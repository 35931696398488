import { createRouter, createWebHashHistory } from 'vue-router';

const routes = [
  {
    name: 'notFound',
    path: '/:path(.*)+',
    redirect: {
      name: 'home',
    },
  },
  {
    name: 'home',
    path: '/home',
    component: () => import('./view/home'),
    meta: {
      title: '首页',
    },
  },
  {
    name: 'physical',
    path: '/physical',
    component: () => import('./view/physical'),
    meta: {
      title: '首页',
    },
  },
  {
    name: 'result',
    path: '/result',
    component: () => import('./view/result'),
    meta: {
      title: '支付结果',
    },
  },
  {
    name: 'goods',
    path: '/goods',
    component: () => import('./view/goods'),
    meta: {
      title: '商品详情',
      user_scalable: 'no'
    },
  },
  {
    name: 'real',
    path: '/real',
    component: () => import('./view/goods/real_good'),
    meta: {
      title: '商品详情',
      user_scalable: 'no'
    },
  },
  {
    name: 'address',
    path: '/address',
    component: () => import('./view/address'),
    meta: {
      title: '地址列表',
      user_scalable: 'no'
    },
  },
  {
    name: 'edit',
    path: '/edit',
    component: () => import('./view/address/edit'),
    meta: {
      title: '地址编辑',
      user_scalable: 'no'
    },
  },
  {
    name: 'detail',
    path: '/detail',
    component: () => import('./view/goods/detail'),
    meta: {
      title: '订单确认',
    },
  },
  {
    name: 'order',
    path: '/order',
    component: () => import('./view/order'),
    meta: {
      title: '订单列表',
    },
  },
  {
    name: 'orderDetail',
    path: '/orderDetail',
    component: () => import('./view/order/detail'),
    meta: {
      title: '订单详情',
    },
  },
  {
    name: 'stock',
    path: '/stock',
    component: () => import('./view/stock'),
    meta: {
      title: '库存不足',
    },
  },
  {
    name: 'physical_order',
    path: '/physical_order',
    component: () => import('./view/order/physical_order'),
    meta: {
      title: '订单列表',
    },
  },
];

const router = createRouter({
  routes,
  history: createWebHashHistory(),
});

router.beforeEach((to, from, next) => {
  document.body.scrollTop = 0;// firefox浏览器中是这句
  document.documentElement.scrollTop = 0;
  const title = to.meta && to.meta.title;
  if (title) {
    document.title = title;
  }
  next();
});

export { router };
